import React, { useRef } from 'react';
import { styled, css } from '../../stitches.config';
import useMedia from '../../Shared/Hooks/useMedia';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import usePreventBackgroundScroll from '../../Shared/Hooks/usePreventBackgroundScroll';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import CloseButton from '../../Atoms/Buttons/CloseButton';

type PropType = {
  children: React.ReactNode;
  toggle: () => void;
  overlay: boolean;
  closeLabel: string;
  clearCart: boolean;
  outSideClick?: boolean;
};

function Modal({
  children,
  toggle,
  overlay,
  closeLabel,
  clearCart,
  outSideClick = true,
}: PropType) {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, () => {
    outSideClick && overlay && toggle();
  });

  const desktopOnly = useMedia(mediaQueryTypes.mediaMinLarge);

  usePreventBackgroundScroll();

  return (
    <ModalContainer overlay={overlay}>
      <ModalDiv ref={modalRef} clearCart={clearCart}>
        {children}
        {closeLabel && (
          <CloseButton
            css={desktopOnly ? HoverCss : {}}
            text={closeLabel}
            toggle={() => toggle()}
          />
        )}
      </ModalDiv>
    </ModalContainer>
  );
}

const HoverCss = css({
  zIndex: '$Flyout',
  position: 'absolute',
  ls: '$ls08',
  fs: 10,
  t: 8,
  l: 8,
});

const ModalContainer = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  h: '100vh',
  w: '100vw',
  zIndex: '$Modal',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $mediaMinLarge: {
    p: 8,
  },
  variants: {
    overlay: {
      true: {
        '&:before': {
          w: '100vw',
          h: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '$black',
          opacity: '0.5',
          content: '""',
        },
      },
    },
  },
});

const ModalDiv = styled('div', {
  backgroundColor: '$white',
  width: '100%',
  m: 2,
  $mediaMinMedium: {
    w: 'auto',
    h: '100%',
  },
  $mediaMinLarge: {
    maxH: 'unset',
    w: '100%',
  },
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    clearCart: {
      true: {
        width: '330px',
        borderRadius: '8px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '32px',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '1px',
        h3: {
          fontSize: '18px',
          fontWeight: '600',
          lineHeight: '26px',
          letterSpacing: '1px',
        },
      },
    },
  },
});

export default Modal;
