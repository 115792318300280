// import ContentAreaItem from 'Models/App/ContentAreaItem.interface';

/**
 * Group Array by size param
 * if size is 1 [1,2] will return [[1], [2]],
 * if size is 2 [1,2] will return [[1,2]]
 */
export const groupArray = (arr: any[], size = 1) => {
  const newArray = [];
  const copiedArr = [...arr];
  while (copiedArr.length > 0) newArray.push(copiedArr.splice(0, size));

  return newArray;
};
