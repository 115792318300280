import { useState, useEffect, useCallback } from 'react';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import IProductPage from '../../ProductPage/Models/ProductPageModel.interface';
import { styled } from '../../stitches.config';
import { H1 } from '../../Atoms/Typography/Headings/Heading';
import { ValueDropDown } from '../../Molecules/ValueDropDown/ValueDropDown';
import { UpdateCart } from '../../Pages/CartPage/Cart';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import QuantitySelector from '../../Atoms/Quantity/QuantitySelector';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import PriceModel from '../../KexVariation/Models/PriceModel.interface';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';

type PropTypes = {
  productPage: IProductPage;
  onReadMore?: () => void;
  hideRule1: boolean;
  hideRule2: boolean;
  hideFromMarket: boolean;
};

function AttributeArea({
  productPage,
  onReadMore,
  hideRule1,
  hideRule2,
  hideFromMarket,
}: PropTypes) {
  const {
    'productPage/yourCampaignPrice': campaignPriceLabel,
    'productPage/yourPrice': yourPriceLabel,
    'productPage/listPrice': listPriceLabel,
    'productPage/selectSize': selectSizeLabel,
    'productPage/addToCart': addToCartLabel,
    'productPage/inStock': inStockLabel,
    'productPage/notInStock': notInStockLabel,
    'productPage/exclVat': exclVatLabel,
    'productPage/noColor': noColorLabel,
    'productPage/noSize': noSizeLabel,
    'productPage/variant': variantLabel,
    'productPage/readMore': readMoreLabel,
    'product/incomingStock': incomingStockLabel,
    'product/artno': artnoLabel,
    'productPage/articleExpired': articleExpiredLabel,
    'productPage/articleIsNotForSale': articleNotForSaleLabel,
  } = useTranslationData();

  const [currentColor] = useState(
    productPage.color ? productPage.color : noColorLabel
  );
  const [currentSize] = useState<string>(
    productPage.size ? productPage.size : noSizeLabel
  );

  const [quantity, setQuantity] = useState<number>(
    productPage.minimumSalesQuantity
  );
  const [code] = useState<string>(productPage.code);
  const [availableSizes, setAvailableSizes] = useState<string[]>();
  const [price] = useState<PriceModel>(productPage.price);
  const [showAddToCartError, toggleAddToCartError] = useState<boolean>(false);
  const [addToCartErrorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { languageRoute } = useAppSettingsData();
  const litiumContext = JSON.stringify({
    currentPageSystemId: productPage.pageId,
    channelSystemId: productPage.channelId,
  });

  const inStock =
    productPage.inStockQuantity && productPage.inStockQuantity > 0;

  const incoming = productPage.backInStockDate !== '';
  const { authenticated } = useUserStateData();

  const kexNavigate = useKexNavigate();

  const updateSizesByColor = useCallback(
    (color: string) => {
      var sizes = new Array<string>();
      productPage.variationCollection.forEach(
        (variant) => variant.color === color && sizes.push(variant.size)
      );
      setAvailableSizes(sizes);
    },
    [productPage.variationCollection]
  );

  useEffect(() => {
    updateSizesByColor(productPage.variationCollection[0].color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPage.variationCollection, updateSizesByColor]);

  const hasPrice = price ? true : false;

  function addToCart() {
    currentSize === selectSizeLabel && toggleAddToCartError(true);
    currentSize === selectSizeLabel && setErrorMessage(selectSizeLabel);

    !hasPrice && toggleAddToCartError(true);
    !hasPrice && setErrorMessage('This variant has no price');

    if (hasPrice && code != null && currentSize !== selectSizeLabel) {
      setIsLoading(true);
      UpdateCart(code, quantity, languageRoute, litiumContext)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }

  const onQuantityChange = (value: number) => {
    setQuantity(value);
  };

  const onColorChange = (value: string) => {
    let variant = productPage.variationCollection.find(
      (x) => x.color === value
    );
    if (variant && variant.to) {
      kexNavigate(variant.to);
    }
  };

  const onSizeChange = (value: string) => {
    let variant = productPage.variationCollection.find((x) => x.size === value);
    if (variant && variant.to) {
      kexNavigate(variant.to);
    }
  };

  const parseBackInStock = (value: string) => {
    var splitStr = value.split('-');

    return `${splitStr[1]}, ${splitStr[0]}`;
  };

  return (
    <AttrContainerWrapper>
      {productPage.brand && (
        <Brand>{productPage.brand && <span>{productPage.brand}</span>}</Brand>
      )}
      <HeadingWrapper>
        <StyledH1 noMargin>{productPage.displayName}</StyledH1>
        <Code>
          <span>
            {artnoLabel} {code}
          </span>
        </Code>
      </HeadingWrapper>
      {!hideRule1 && hideRule2 && (
        <NotForSaleCountry>
          <span>{articleNotForSaleLabel}</span>
        </NotForSaleCountry>
      )}
      {hideRule1 && (
        <ArticleExpired>
          <span>{articleExpiredLabel}</span>
        </ArticleExpired>
      )}
      {authenticated && price.hasPrice && !hideFromMarket ? (
        <>
          {price.campaignPrice?.priceFormatted ? (
            <CampaignListPrice>
              {campaignPriceLabel} {price.campaignPrice?.priceFormatted}{' '}
              <span>{exclVatLabel}</span>
            </CampaignListPrice>
          ) : (
            <Price authenticated={true}>
              {yourPriceLabel} {price.price?.priceFormatted}{' '}
              <span>{exclVatLabel}</span>
            </Price>
          )}
          {price.defaultPrice && !productPage.isNetPrice && (
            <ListPrice>
              {listPriceLabel} {price.defaultPrice?.priceFormatted}{' '}
              <span>{exclVatLabel}</span>
            </ListPrice>
          )}
        </>
      ) : (
        !productPage.isNetPrice && (
          <ListPrice>
            {listPriceLabel} {price.defaultPrice?.priceFormatted}{' '}
            <span>{exclVatLabel}</span>
          </ListPrice>
        )
      )}
      {!productPage.hideStockStatus && (
        <StockStatus>
          {inStock ? (
            <>
              <StockStatusCircle inStock={inStock} />
              <StockStatusText>
                {productPage.inStockQuantity} {inStockLabel}
              </StockStatusText>
            </>
          ) : (
            <>
              <StockStatusCircle incoming={incoming} />
              <StockStatusText>
                {productPage?.backInStockDate
                  ? `${incomingStockLabel} ${parseBackInStock(
                      productPage.backInStockDate
                    )}`
                  : notInStockLabel}
              </StockStatusText>
            </>
          )}
        </StockStatus>
      )}
      {productPage.uspList && (
        <UspList>
          {productPage.uspList.map((usp: string) => (
            <UspListItem key={usp}>{usp}</UspListItem>
          ))}
        </UspList>
      )}
      {(productPage.extendedDescription || productPage.shortDescription) && (
        <ReadMoreButton onClick={onReadMore}>{readMoreLabel}</ReadMoreButton>
      )}
      {productPage.availableColors && productPage.availableColors.length > 1 && (
        <ColorContainer>
          <VariantText>{variantLabel}</VariantText>
          <ValueDropDown
            values={productPage.availableColors}
            currentValue={currentColor}
            setSelectedValue={onColorChange}
            fallbackValue={noColorLabel}
          />
        </ColorContainer>
      )}
      {productPage.availableSizes && productPage.availableSizes.length > 1 && (
        <>
          <SizeAttributeProperty>
            <AttributeProperty>{selectSizeLabel}:</AttributeProperty>
          </SizeAttributeProperty>
          <ValueDropDown
            values={availableSizes}
            currentValue={currentSize}
            setSelectedValue={onSizeChange}
            fallbackValue={noSizeLabel}
          />
        </>
      )}
      {authenticated &&
        price?.defaultPrice?.price !== 0 &&
        (price?.price?.price !== 0 || price?.campaignPrice?.price !== 0) &&
        !hideFromMarket && (
          <AddToCartContainer>
            <QuantitySelectorWrapper>
              <QuantitySelector
                onChange={onQuantityChange}
                value={quantity?.toString()}
                minimumSalesQuantity={productPage.minimumSalesQuantity}
                noMaxHeight
                salesUnit={
                  price?.price?.unitOfMeasurementTitle ||
                  price?.campaignPrice?.unitOfMeasurementTitle
                }
              />
            </QuantitySelectorWrapper>
            <CtaButton
              onClick={() => addToCart()}
              isLoading={isLoading}
              type={'secondary'}
              uppercase={true}
            >
              <AddToCartText>{addToCartLabel}</AddToCartText>
            </CtaButton>
            {showAddToCartError && (
              <AddToCartErrorContainer>
                <AddToCartError>{addToCartErrorMessage}</AddToCartError>
              </AddToCartErrorContainer>
            )}
          </AddToCartContainer>
        )}
    </AttrContainerWrapper>
  );
}

const HeadingWrapper = styled('div', {
  mb: 5,
  '@mediaMinLarge': {
    mb: 6,
  },
});

const StyledH1 = styled(H1, {
  fs: 10,
  lineHeight: '28px',
  '@mediaMinMedium': {
    fs: 12,
    lineHeight: '32px',
  },
});

const VariantText = styled('div', {
  fs: 5,
  mb: 2,
  lineHeight: '16px',
  '@mediaMinLarge': {
    fs: 6,
    lineHeight: '24px',
  },
});

const NotForSaleCountry = styled('div', {
  color: '$errorPrimary',
});

const ArticleExpired = styled('div', {
  color: '$errorPrimary',
});

const AddToCartErrorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ml: 16,
  h: 30,
  bw: 0.25,
  borderColor: '$errorPrimary',
  borderStyle: 'solid',
});

const ReadMoreButton = styled('button', {
  fs: 6,
  textDecoration: 'underline',
  mb: 6,
  '@mediaMinLarge': {
    fs: 7,
  },
});

const AddToCartText = styled('span', {});

const QuantitySelectorWrapper = styled('div', {
  '@mediaMinLarge': {
    mr: 2,
  },
});

const AddToCartError = styled('span', {
  color: '$errorPrimary',
  textAlign: 'center',
  fs: 7,
  lineHeight: '$lh2',
  ls: '$ls08',
});

const StockStatus = styled('div', {
  display: 'flex',
  alignItems: 'center',
  my: '24px',
});

const UspList = styled('ul', {
  listStyle: 'disc',
  mb: '24px',
});

const UspListItem = styled('li', {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '1px',
  ml: 4.25,

  '@mediaMinMedium': {
    ml: 4.5,
  },
});

const StockStatusCircle = styled('div', {
  br: '50%',
  wh: 2,
  backgroundColor: '$errorPrimary',
  top: '-1px',
  position: 'relative',
  variants: {
    inStock: {
      true: {
        backgroundColor: '$green',
      },
    },
    incoming: {
      true: {
        backgroundColor: '#7B7978',
      },
    },
  },
  mr: 2,
});

const StockStatusText = styled('span', {
  color: '$black',
  fs: 6,
  fontWeight: '$fw400',
  letterSpacing: '1px',
  '@mediaMinLarge': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

const ColorContainer = styled('div', {
  mb: 3,
});

const AttributeProperty = styled('p', {
  h: 5,
  mb: 3,
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh125',
  ls: '$ls08',
  color: '$grey1000',
});

const SizeAttributeProperty = styled('span', {
  mb: 2,
});

const AddToCartContainer = styled('div', {
  display: 'flex',
  g: '24px',
  flexDirection: 'column',
  pb: 8,
  pt: 8,
  '@mediaMinLarge': {
    flexDirection: 'row',
    pb: 0,
  },
});

const Brand = styled('div', {
  fontSize: '8px',
  lineHeight: '16px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontWeight: '$fw400',
  mb: 1,
  '@mediaMinLarge': {
    fontSize: '10px',
    lineHeight: '24px',
    mb: 3,
  },
});

const Code = styled('div', {
  fontSize: '8px',
  lineHeight: '16px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontWeight: '$fw400',
  mb: 1,
  '@mediaMinLarge': {
    fontSize: '10px',
    lineHeight: '24px',
    mb: 3,
  },
});

const Price = styled('p', {
  fontWeight: '$fw600',
  fontSize: '14px',
  lineHeight: '23px',
  letterSpacing: '1px',
  m: 0,
  '@mediaMinLarge': {
    fontSize: '18px',
    lineHeight: '26px',
  },
  '& > span': {
    fontSize: '11px',

    '@mediaMinLarge': {
      fontSize: '13px',
    },
  },
  variants: {
    authenticated: {
      false: {
        display: 'none',
      },
    },
  },
});

const ListPrice = styled('p', {
  fontWeight: '$fw400',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '1px',
  m: 0,
  '& > span': {
    fontSize: '9px',
  },
  variants: {
    authenticated: {
      false: {
        fontWeight: '$fw400',
        fontSize: '18px',
        lineHeight: '26px',
        letterSpacing: '1px',
        '& > span': {
          fontSize: '13px',
        },
      },
    },
  },
});

const CampaignListPrice = styled('p', {
  color: '#C85000',
  fontWeight: '$fw400',
  fontSize: '18px',
  lineHeight: '26px',
  letterSpacing: '1px',
  m: 0,
  '& > span': {
    fontSize: '13px',
  },
});

const AttrContainerWrapper = styled('div', {
  w: '100%',
  px: 0,
});

export default AttributeArea;
