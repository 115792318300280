import { styled } from '../../stitches.config';
import KexIconLoader from '../../Kex/KexIconLoader';
import KexLink from '../../Kex/KexLink';

type PropTypes = {
  whiteText?: boolean;
  underline?: boolean;
  onClick?: any;
  href?: string;
  children: any | undefined;
  focused?: any;
  color?: string;
  type?: string;
  useHoverEffect?: boolean;
  size?: string;
  round?: boolean;
  target?: string;
};

function IconLink({
  whiteText = false,
  onClick,
  href,
  children,
  focused,
  color,
  type,
  useHoverEffect = false,
  size,
  round = false,
  target,
  ...rest
}: PropTypes) {
  var Icon = KexIconLoader(type ?? 'Arrow');
  return (
    <StyledLinkWrapper useHoverEffect={useHoverEffect} round={round} {...rest}>
      <StyledKexLink
        {...rest}
        onClick={onClick}
        href={href}
        target={target}
        type={whiteText ? 'secondary' : 'primary'}
        round={round}
      >
        <IconWrapper whiteText={whiteText} round={round}>
          <Icon size={size ?? 's'} color={whiteText ? 'w' : color} />
        </IconWrapper>
        <StyledLinkSpan whiteText={whiteText}>{children}</StyledLinkSpan>
      </StyledKexLink>
    </StyledLinkWrapper>
  );
}

const StyledKexLink = styled(KexLink, {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '15px',
  wh: '100%',
  variants: {
    round: {
      true: {
        p: 4,
        gap: '2px',
        '@mediaMaxLarge': {
          '&:active': {
            zoom: '104%',
          },
        },
        '@mediaMinMedium': {
          fs: 10,
          gap: '10px',
        },
        '@mediaMinLarge': {
          '&:hover': {
            zoom: '104%',
          },
        },
      },
    },
  },
});

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    whiteText: {
      true: {
        fill: '$white',
      },
    },
    round: {
      true: {
        wh: 8,
        justifyContent: 'center',
        '@mediaMinMedium': {
          wh: 15,
        },
      },
    },
  },
});

const StyledLinkWrapper = styled('div', {
  wh: 22,
  transition: `margin $700`,
  '@mediaMinMedium': {
    wh: 36,
  },
  variants: {
    useHoverEffect: {
      true: {
        '&:hover': {
          ml: 2,
        },
      },
    },
    round: {
      true: {
        gridColumnEnd: 'span 2',
        background: '$white',
        br: '100%',
        '@mediaMinVerySmall': {
          gridColumnEnd: 'span 1',
        },
      },
    },
  },
});

const StyledLinkSpan = styled('span', {
  mt: '3px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1px',
  letterSpacing: '1px',
  textAlign: 'center',
  variants: {
    whiteText: {
      true: {
        color: '$white',
      },
    },
  },
});

export default styled(IconLink);
