import React from 'react';
import { Product, WithContext } from 'schema-dts';
import { StructuredData } from './StructuredData';
import ProductPageModel from '../../ProductPage/Models/ProductPageModel.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { off } from 'process';

export interface StructuredProductDataProps {
  /** The product object */
  product: ProductPageModel;
  hideRule1: boolean;
  hideRule2: boolean;
  hideFromMarket: boolean;
}

/** Renders structured product data using Schema.org vocab */
const StructuredProductData: React.FC<StructuredProductDataProps> = ({
  product,
  hideRule1,
  hideRule2,
  hideFromMarket,
}) => {
  const { currency, domain } = useAppSettingsData();

  let offers = [
    {
      '@type': 'Offer',
      priceSpecification: {
        '@type': 'UnitPriceSpecification',
        price:
          !hideFromMarket && product.price.defaultPrice && !product?.isNetPrice
            ? product?.price?.defaultPrice?.price
            : '',
        priceCurrency: currency,
        valueAddedTaxIncluded: false,
      },
      url: product?.openGraph?.url,
      availability: (!hideFromMarket &&
      product?.inStockQuantity &&
      product?.inStockQuantity > 0
        ? 'http://schema.org/InStock'
        : !hideRule1 && hideRule2
        ? 'http://schema.org/OutOfStock'
        : hideRule1
        ? 'http://schema.org/Discontinued'
        : product?.backInStockDate
        ? 'http://schema.org/PreOrder'
        : 'http://schema.org/OutOfStock') as import('schema-dts').ItemAvailability,
    },
  ];

  product?.availableColors?.length > 1 &&
    product?.availableColors?.map((color) => {
      let variant = product.variationCollection
        .filter((x) => x.color !== product.color)
        .find((x) => x.color === color);

      let variantHideRules1 = false;
      let variantHideRules2 = false;
      let variantHideFromMarket = false;
      if (variant) {
        if (
          !variant?.tillForsaljningPaFoljandeKanaler?.includes(
            product.channelId
          )
        ) {
          variantHideRules2 = true;
        }

        if (!variant.visaPaMT && !variant.visaPaZE) {
          variantHideRules1 = true;
        }

        if (
          !variant.priceModel?.defaultPrice ||
          !variant?.tillForsaljningPaFoljandeKanaler?.includes(
            product.channelId
          )
        ) {
          variantHideFromMarket = true;
          variant.hideStockStatus = true;
          variant.isNetPrice = true;
        } else {
          if (!variant.visaPaMT && !variant.visaPaZE) {
            variantHideFromMarket = true;
            variant.hideStockStatus = true;
            variant.isNetPrice = true;
          }
        }

        offers = [
          ...offers,
          {
            '@type': 'Offer',
            priceSpecification: {
              '@type': 'UnitPriceSpecification',
              price:
                !variantHideFromMarket &&
                variant.priceModel.defaultPrice &&
                !variant?.isNetPrice
                  ? variant?.priceModel?.defaultPrice?.price
                  : '',
              priceCurrency: currency,
              valueAddedTaxIncluded: false,
            },
            url: `${domain}${variant?.to}`,
            availability: (!variantHideFromMarket &&
            variant?.inStockQuantity &&
            variant?.inStockQuantity > 0
              ? 'http://schema.org/InStock'
              : !variantHideRules1 && variantHideRules2
              ? 'http://schema.org/OutOfStock'
              : variantHideRules1
              ? 'http://schema.org/Discontinued'
              : variant?.backInStockDate
              ? 'http://schema.org/PreOrder'
              : 'http://schema.org/OutOfStock') as import('schema-dts').ItemAvailability,
          },
        ];
      }
      return null;
    });

  product?.availableSizes?.length > 1 &&
    product?.availableSizes?.map((size) => {
      let variant = product.variationCollection
        .filter((x) => x.size !== product.size)
        .find((x) => x.size === size);

      let variantHideRules1 = false;
      let variantHideRules2 = false;
      let variantHideFromMarket = false;
      if (variant) {
        if (
          !variant?.tillForsaljningPaFoljandeKanaler?.includes(
            product.channelId
          )
        ) {
          variantHideRules2 = true;
        }

        if (!variant.visaPaMT && !variant.visaPaZE) {
          variantHideRules1 = true;
        }

        if (
          !variant.priceModel?.defaultPrice ||
          !variant?.tillForsaljningPaFoljandeKanaler?.includes(
            product.channelId
          )
        ) {
          variantHideFromMarket = true;
          variant.hideStockStatus = true;
          variant.isNetPrice = true;
        } else {
          if (!variant.visaPaMT && !variant.visaPaZE) {
            variantHideFromMarket = true;
            variant.hideStockStatus = true;
            variant.isNetPrice = true;
          }
        }

        offers = [
          ...offers,
          {
            '@type': 'Offer',
            priceSpecification: {
              '@type': 'UnitPriceSpecification',
              price:
                !variantHideFromMarket &&
                variant.priceModel.defaultPrice &&
                !variant?.isNetPrice
                  ? variant?.priceModel?.defaultPrice?.price
                  : '',
              priceCurrency: currency,
              valueAddedTaxIncluded: false,
            },
            url: `${domain}${variant?.to}`,
            availability: (!variantHideFromMarket &&
            variant?.inStockQuantity &&
            variant?.inStockQuantity > 0
              ? 'http://schema.org/InStock'
              : !variantHideRules1 && variantHideRules2
              ? 'http://schema.org/OutOfStock'
              : variantHideRules1
              ? 'http://schema.org/Discontinued'
              : variant?.backInStockDate
              ? 'http://schema.org/PreOrder'
              : 'http://schema.org/OutOfStock') as import('schema-dts').ItemAvailability,
          },
        ];
      }
      return null;
    });

  let baseProductData: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product?.displayName,
    description: product.extendedDescription
      ? product.extendedDescription
      : product.shortDescription
      ? product.shortDescription
      : '',
    image: (product?.sliderImages || []).map(
      (image) => `${domain}${image.src}`
    ),
    brand: {
      '@type': 'Brand',
      name: product?.brand,
    },
    sku: product?.code,
    productID: product?.code,
    category: product?.categoryName,
    offers: offers,
  };

  let extraFields = {};
  extraFields = Object.assign({}, product.structuredData);

  const withInjectedProductData = {
    ...baseProductData,
    ...(extraFields as Product),
  };

  return <StructuredData json={withInjectedProductData} />;
};

export { StructuredProductData };
