import { useState, useRef } from 'react';
import { styled } from '../../stitches.config';
import { ChevronIcon } from '../../Atoms/Icons';

type PropTypes = {
  values: string[] | undefined;
  currentValue: string;
  setSelectedValue: (value: string) => void;
  fallbackValue?: string;
};

export function ValueDropDown({
  values,
  currentValue,
  setSelectedValue,
  fallbackValue,
}: PropTypes) {
  const [showValues, toggleValues] = useState(false);
  const ref = useRef(null);
  const [height, setHeight] = useState<string>('0px');
  const dropDownContent = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    toggleValues(!showValues);
    setHeight(
      showValues ? '0px' : `${dropDownContent?.current?.scrollHeight}px`
    );
  };
  return (
    <DropDownContainer>
      <ValueFlex onClick={() => toggleDropdown()} ref={ref}>
        <CurrentValue>{currentValue} </CurrentValue>
        <ChevronIcon
          size={'xs'}
          css={{
            mr: 4.5,
            transition: 'all $500',
            transform: showValues ? 'rotate(270deg)' : 'rotate(90deg)',
          }}
        />
      </ValueFlex>
      <DropDown ref={dropDownContent} css={{ maxHeight: `${height}` }}>
        {values &&
          values.length > 0 &&
          values.map((value: string) => (
            <DropDownButton
              key={value}
              onClick={() => {
                setSelectedValue(value ? value : undefined);
                toggleValues(false);
              }}
              selected={
                value === currentValue ||
                (value === null && currentValue === fallbackValue) ||
                (value === undefined && currentValue === fallbackValue)
              }
            >
              {value ? value : fallbackValue}
            </DropDownButton>
          ))}
      </DropDown>
    </DropDownContainer>
  );
}

export default ValueDropDown;

const DropDownButton = styled('button', {
  w: '-webkit-fill-available',
  h: '100%',
  fs: 6,
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
  },
  py: 4,
  px: 6,
  '@mediaMinLarge': {
    fs: 7,
  },
  variants: {
    selected: {
      true: {
        opacity: 0.5,
        cursor: 'default',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
});

const DropDownContainer = styled('div', {
  mb: 4.5,
  w: 'auto',
  minH: 10,
  position: 'relative',
  h: 'auto',
  display: 'block',
  textAlign: 'left',
  border: '1px solid $valueDropdownContainerBorder',
  br: '6px',
  overflow: 'hidden',
});

const ValueFlex = styled('div', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  h: 10,
  alignItems: 'center',
  cursor: 'pointer',
  pl: 2,
  pr: 1.5,
  '@mediaMinLarge': {
    h: 14,
  },
});

const DropDown = styled('div', {
  maxHeight: 0,
  overflowY: 'hidden',
  transition: 'max-height $500 ease',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #valueDropdownBorder',
  backgroundColor: '$valueDropdownBackgroundPrimary',
  w: '100%',
  zIndex: '$DropDown',
});

const CurrentValue = styled('span', {
  fs: 6,
  lineHeight: '$lh2',
  ls: '$ls07',
  color: '$valueDropdownTextPrimary',
  ml: 4,
  '@mediaMinLarge': {
    fs: 7,
  },
});
