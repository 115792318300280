import { useState, useRef } from 'react';
import loadable from '@loadable/component';
import Image from '../../Atoms/Image/Image';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import usePreventBodyScroll from './usePreventBodyScroll';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import { styled } from '../../stitches.config';
import Dots from '../../Atoms/Loaders/Dots';
import ChevronIcon from '../../Atoms/Icons/ChevronIcon';
import { triggerResizeEvent } from '../../Shared/Common/Helpers';
import { timings } from '../../Theme/Settings/animation';
import Modal from '../../Organisms/Modal/Modal';
import ModalImage from '../../Atoms/Image/ContentImage';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

const CarouselLib = loadable.lib(() => import('nuka-carousel'));

type PropTypes = {
  sliderImages: any[];
  productName: string;
};
function ProductImagesContainer({ sliderImages, productName }: PropTypes) {
  const { 'common/close': closeLabel } = useTranslationData();

  const [showModal, toggleModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const refContainer = useRef<HTMLDivElement>(null);
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const carouselAssets = sliderImages.reduce(
    (acc, { src, alt }, i) => {
      if (src) {
        acc.imageSlides.push(
          <MediaContentRoot key={i + src} onClick={() => toggleModal(true)}>
            <MainImage src={src} alt={productName} />
          </MediaContentRoot>
        );
      }
      acc.thumbnails.push(
        <IconButton
          key={i + src}
          itemID={i + src}
          onClick={() => {
            onSlideChange(i);
          }}
          onKeyPress={() => {
            onSlideChange(i);
          }}
          selected={i === currentSlide}
          last={i === sliderImages.length - 1}
        >
          <Thumbnail src={`${src}`} alt={productName + i} />
        </IconButton>
      );

      return acc;
    },
    {
      imageSlides: [],
      thumbnails: [],
    }
  );

  const onSlideChange = (slideNbr: number) => {
    if (currentSlide !== slideNbr) {
      //prevents loop when changing slides fast
      setCurrentSlide(slideNbr);
    }
    if (refContainer == null || refContainer.current == null) return;

    let scrollContainer = refContainer.current?.querySelector(
      '.react-horizontal-scrolling-menu--scroll-container'
    ) as HTMLElement | null;
    let cardWidth = refContainer?.current?.querySelector('button')?.offsetWidth;
    if (scrollContainer !== null && cardWidth !== undefined) {
      let btnPos = cardWidth * slideNbr;
      let scrollPos = btnPos - scrollContainer?.offsetWidth / 2;
      scrollContainer.scrollTo({
        left: scrollPos,
      });
    }
  };

  return (
    <>
      <Root>
        {carouselAssets.imageSlides.length < 2 ? (
          carouselAssets.imageSlides
        ) : (
          <>
            <CarouselLib fallback={<Dots />}>
              {({ default: Carousel }) => (
                <>
                  <Carousel
                    speed={500}
                    slideIndex={currentSlide}
                    afterSlide={onSlideChange}
                    renderCenterLeftControls={({ previousSlide }) =>
                      currentSlide !== 0 || isDesktop ? (
                        <div>
                          <SliderButton
                            onClick={previousSlide}
                            hide={currentSlide === 0}
                            left={true}
                          >
                            <ChevronIcon color="white" size="xs" rotateLeft />
                          </SliderButton>
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    renderCenterRightControls={({ nextSlide }) => (
                      <div>
                        <SliderButton
                          onClick={nextSlide}
                          hide={
                            currentSlide ===
                            carouselAssets.imageSlides.length - 1
                          }
                        >
                          <ChevronIcon color="white" size="xs" />
                        </SliderButton>
                      </div>
                    )}
                    renderBottomCenterControls={() => <></>}
                  >
                    {carouselAssets.imageSlides}
                  </Carousel>
                  {triggerResizeEvent()}
                </>
              )}
            </CarouselLib>
          </>
        )}
        {isDesktop && carouselAssets.imageSlides.length > 1 && (
          <ThumbnailsContainer
            onMouseEnter={disableScroll}
            onMouseLeave={enableScroll}
            ref={refContainer}
          >
            <ScrollMenu
              onWheel={onWheel}
              wrapperClassName={'reactHorizontalScroll'}
            >
              {carouselAssets.thumbnails}
            </ScrollMenu>
          </ThumbnailsContainer>
        )}
      </Root>
      {showModal && (
        <Modal
          overlay={true}
          toggle={() => toggleModal(false)}
          closeLabel={closeLabel}
        >
          <ModalImage image={sliderImages[currentSlide]} />
        </Modal>
      )}
    </>
  );
}

function onWheel(apiObj: any, ev: any) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const SliderButton = styled('button', {
  transitionDuration: timings.oneHalf,
  transitionProperty: 'all',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  wh: '32px',
  mr: 4,
  br: '100%',
  background: '$primary',
  variants: {
    hide: {
      true: {
        visibility: 'hidden',
        opacity: 0,
      },
    },
    left: {
      true: {
        mr: 0,
        ml: 4,
      },
    },
  },
});

const MainImage = styled(Image, {
  wh: '100%',
  objectFit: 'contain',
  pb: 2,
  '@mediaMaxMedium': {
    maxH: '$productImageMobileMaxHeight',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '@mediaMinLarge': {
    aspectRatio: '1 / 1',
    pb: 0,
  },
});

const MediaContentRoot = styled('div', {
  wh: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  '@mediaMinLarge': {
    h: 'fit-content',
  },
});

const Thumbnail = styled('img', {
  br: 2,
  wh: '100%',
  objectFit: 'contain',
});

const IconButton = styled('button', {
  wh: 17.75,
  br: 2,
  mr: 4,
  border: '1px solid $grey100',
  variants: {
    selected: {
      true: {
        border: '1px solid #494948',
      },
    },
    last: {
      true: {
        mr: 0,
      },
    },
  },
});

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  g: 4,
  h: '100%',
});

const ThumbnailsContainer = styled('div', {
  display: 'flex',
  l: 0,
  t: 0,
  w: '100%',
});

export default ProductImagesContainer;
