import { useEffect } from 'react';

function usePreventBackgroundScroll() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);
}

export default usePreventBackgroundScroll;
