import { styled } from '../../stitches.config';
import ImageModel from '../../Assets/Models/ImageModel.interface';

type PropTypes = {
  image: ImageModel;
};

function Image({ image }: PropTypes) {
  return <ModalImage src={image.src} />;
}

const ModalImage = styled('img', {
  w: '100%',
  p: 2,
  maxH: '$productImageMaxHeight',
  aspectRatio: '1/1',
  objectFit: 'contain',
  '@mediaMinSmall': {
    py: 4,
    w: 'auto',
    h: '100%',
  },
  '@mediaMinMedium': {
    maxH: '$productImageMaxHeightLarge',
  },
  '@mediaMinLarge': {
    py: 10,
    px: 'unset',
  },
});

export default Image;
