import { styled } from '../../stitches.config';
import CloseIcon from '../Icons/CloseIcon';
import BaseButtonStyled from './BaseButtonStyle';

type CloseButtonBaseType = {
  text: string;
  toggle?: () => void;
  css?: any;
};

function CloseButton({ text, toggle, css }: CloseButtonBaseType) {
  return (
    <CloseBtn css={css} onClick={() => toggle && toggle()}>
      <CloseIcon css={iconCss} color="white" />
      {text}
    </CloseBtn>
  );
}
export default CloseButton;

const CloseBtn = styled(BaseButtonStyled, {
  //Custom variables
  $$shadow005: '$colors$shadow005',
  $$shadow015: '$colors$shadow015',
  maxWidth: '$mobileMenuMaxWidth',
  w: '100%',
  //Overrides
  lineHeight: '$lh15',
  position: 'fixed',
  p: 4,
  py: 3,
  bw: 0,

  //Custom properties
  l: '50%',
  b: 6,
  br: 8,
  transform: 'translateX(-50%)',
  boxShadow: '0 2px 32px 2px $$shadow005, 0 0 4px 0 $$shadow015',
  zIndex: '$Header',

  //Set default for button
  defaultVariants: {
    color: 'secondary',
  },
});

const iconCss = {
  mr: 2,
  pb: 0.5,
  w: 4,
  h: 4,
  color: '$primary',
};
