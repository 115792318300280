import { styled } from '../../stitches.config';
import React, { useState } from 'react';
import MinusIcon from '../Icons/MinusIcon';
import PlusIcon from '../Icons/PlusIcon';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

type PropTypes = {
  onChange: (value: number) => void;
  value: string;
  minimumSalesQuantity: number;
  noMaxHeight?: boolean;
  fullWidth?: boolean;
  salesUnit?: string;
};

function QuantitySelector({
  onChange,
  value,
  noMaxHeight = false,
  minimumSalesQuantity = 1,
  salesUnit,
}: PropTypes) {
  const { 'product/salesQuantity': salesQuantityLabel } = useTranslationData();

  const [quantityError, setQuantityError] = useState(false);
  const onQuantityMinus = () => {
    onChange(Number(value) - minimumSalesQuantity);
    setQuantityError(false);
  };

  const onQuantityPlus = () => {
    onChange(Number(value) + minimumSalesQuantity);
    setQuantityError(false);
  };

  const onQuantityBlur = (event) => {
    if (
      Math.ceil(Number(event.target.value) / minimumSalesQuantity) *
        minimumSalesQuantity !==
      Number(event.target.value)
    ) {
      setQuantityError(true);
    }

    if (Number(event.target.value) > minimumSalesQuantity) {
      onChange(
        Math.ceil(Number(event.target.value) / minimumSalesQuantity) *
          minimumSalesQuantity
      );
    } else {
      onChange(minimumSalesQuantity);
    }
  };

  const onQuantityFocus = () => {
    setQuantityError(false);
    onChange(null);
  };

  const onQuantityChange = (event) => {
    event.target.style.width = event?.target?.value?.length * 10 + 'px';
  };

  return (
    <QuantityWrapper noMaxHeight={noMaxHeight}>
      <>
        <MinusButton
          disabled={value === minimumSalesQuantity?.toString()}
          onClick={() => onQuantityMinus()}
        >
          <StyledMinusIcon size={'xs'} />
        </MinusButton>
        <SalesUnitWrapper>
          <Quantity
            onBlur={onQuantityBlur}
            placeholder={value}
            onFocus={onQuantityFocus}
            value={value}
            onChange={onQuantityChange}
            id={'productQuantity'}
            style={{ width: value?.length * 10 + 'px' }}
          />
          {salesUnit && (
            <QuantityLabel
              htmlFor={'productQuantity'}
              style={{
                width: salesUnit?.length * 10,
              }}
            >
              {salesUnit}
            </QuantityLabel>
          )}
        </SalesUnitWrapper>
        <PlusButton onClick={() => onQuantityPlus()}>
          <StyledPlusIcon size={'xs'} />
        </PlusButton>
        {quantityError && <QuantityError>{salesQuantityLabel}</QuantityError>}
      </>
    </QuantityWrapper>
  );
}

export default QuantitySelector;

const QuantityWrapper = styled('div', {
  flexBasis: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  g: '8px',
  h: '100%',
  maxH: 9,
  variants: {
    noMaxHeight: {
      true: {
        maxH: '100%',
      },
    },
    fullWidth: {
      true: {
        flexBasis: '100%',
      },
    },
  },
});

const QuantityLabel = styled('label', {
  '@mediaMinLarge': {
    width: '50%',
    textAlign: 'center',
  },
});

const SalesUnitWrapper = styled('div', {
  height: '40px',
  w: '100%',
  paddingRight: '5px',
  paddingLeft: '5px',
  background: '$white',
  border: '1px solid #7B7978',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '@mediaMinLarge': {
    height: '56px',
    minW: '100px',
  },
});

const QuantityError = styled('span', {
  position: 'absolute',
  color: '#9a2626',
  bottom: '-25px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
});

const Quantity = styled('input', {
  display: 'flex',
  background: 'transparent',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  textAlign: 'center',
  minW: '20px',
  '&:active': {
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
});

const MinusButton = styled('button', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  minWidth: '40px',
  minHeight: '40px',
  background: '#F7F6F5',
  border: '1px solid #7B7978',
  borderRadius: '6px',
  '@mediaMinLarge': {
    minWidth: '56px',
    minHeight: '56px',
  },
  '&:disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const PlusButton = styled('button', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  minWidth: '40px',
  minHeight: '40px',
  background: '#F7F6F5',
  border: '1px solid #7B7978',
  borderRadius: '6px',
  '@mediaMinLarge': {
    minWidth: '56px',
    minHeight: '56px',
  },
});

const StyledPlusIcon = styled(PlusIcon, {
  wh: 4.5,
});

const StyledMinusIcon = styled(MinusIcon, {
  wh: 4.5,
});
