import loadable from '@loadable/component';
import { styled } from '../../stitches.config';
import { groupArray } from './Utils/groupArray';
import IProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import ProductCard from '../../Organisms/ProductCard/ProductCard';
import Grid from '../../Atoms/Grids/Grid';
import useMedia from '../Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import { ArrowIcon } from '../../Atoms/Icons';
import { timings } from '../../Theme/Settings/animation';

const CarouselLib = loadable.lib(() => import('nuka-carousel'));

function ItemListingBlockSlider(products: IProductCardModel[]) {
  const isSmallMobile = useMedia(mediaQueryTypes.mediaMaxVerySmall);
  const isLargeMobile = useMedia(mediaQueryTypes.mediaMinVerySmall);
  const isTablet = useMedia(mediaQueryTypes.mediaMinMedium);
  const isLargeDesktop = useMedia(mediaQueryTypes.mediaMinHuge);

  const getSliderCount = () => {
    if (isSmallMobile) {
      return 1;
    } else if (isLargeMobile && !isTablet) {
      return 2;
    } else if (isTablet && !isLargeDesktop) {
      return 3;
    } else if (isLargeDesktop) {
      return 4;
    }
  };

  const slidesToShow = getSliderCount();
  const groupedProducts = groupArray(products, slidesToShow);
  return () => {
    return (
      <CarouselLib fallback={<div />}>
        {({ default: Carousel }) => (
          <div>
            <Carousel
              style={{
                height: '100%',
              }}
              speed={600}
              cellSpacing={16}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                <SliderButton
                  onClick={previousSlide}
                  hide={currentSlide === 0}
                  left={true}
                >
                  <ArrowIcon size="xs" rotateLeft color={'white'} />
                </SliderButton>
              )}
              renderCenterRightControls={({ nextSlide, currentSlide }) => (
                <SliderButton
                  onClick={nextSlide}
                  hide={currentSlide === groupedProducts?.length - 1}
                >
                  <ArrowIcon size="xs" color={'white'} />
                </SliderButton>
              )}
              renderBottomCenterControls={() => <></>}
            >
              {groupedProducts &&
                groupedProducts.map(
                  (productsSlide: IProductCardModel[], index) => {
                    return (
                      <Grid key={index}>
                        {productsSlide.map((product: IProductCardModel) => (
                          <ProductCard
                            key={product.code + product.name}
                            item={product}
                          />
                        ))}
                      </Grid>
                    );
                  }
                )}
            </Carousel>
          </div>
        )}
      </CarouselLib>
    );
  };
}

const SliderButton = styled('button', {
  transitionDuration: timings.oneHalf,
  transitionProperty: 'all',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  wh: '32px',
  mr: 4,
  br: '100%',
  background: '$primary',
  variants: {
    hide: {
      true: {
        visibility: 'hidden',
        opacity: 0,
      },
    },
    left: {
      true: {
        mr: 0,
        ml: 4,
      },
    },
  },
});

export default ItemListingBlockSlider;
