import IProductPage from '../../ProductPage/Models/ProductPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import ContentContainer from '../../Molecules/ContentContainer/ContentContainer';
import AttributeArea from './AttributeArea';
import ContentArea from '../../Organisms/ContentArea/ContentArea';
import ProductImagesContainer from './ProductImagesContainer';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import Grid from '../../Atoms/Grids/Grid';
import { styled } from '../../stitches.config';
import ItemListingBlock from '../../Organisms/Blocks/ItemListingBlock';
import ItemListingBlockModel from '../../Blocks/ItemListingBlock/Models/ItemListingBlockModel.interface';
import { createCookie, readCookie } from '../../Shared/Common/Cookie';
import { useEffect, useRef, useState } from 'react';
import DocumentModel from '../../Assets/Models/DocumentModel.interface';
import IconLink from '../../Atoms/Links/IconLink';
import { LinkType } from '../../Enums/LinkType.enum';
import Accordion from '../../Molecules/Accordion/Accordion';
import { scrollWithOffset } from '../../Shared/Common/Helpers';
import { StructuredProductData } from '../../Molecules/StructuredData/StructuredProductData';

function ProductPage() {
  const {
    'productPage/relatedProducts': relatedProductsLabel,
    'productPage/accessories': accessoriesLabel,
    'productPage/recentlyVisited': recentlyVisitedLabel,
    'productPage/details': detailsLabel,
    'productPage/description': descriptionLabel,
    'productPage/downloadImages': downloadImagesLabel,
  } = useTranslationData();

  const productPageData = useCurrentPage<IProductPage>();
  var variantCode = productPageData.code;
  const [openDescriptionAccordion, setOpenDescriptionAccordion] =
    useState<boolean>(false);
  const descriptionRef = useRef(null);
  const [hideRule1, setHideRule1] = useState<boolean>(false);
  const [hideRule2, setHideRule2] = useState<boolean>(false);
  const [hideFromMarket, setHideFromMarket] = useState<boolean>(false);

  const {
    sliderImages,
    code,
    contentArea,
    accessories,
    similarProducts,
    recentlyVisitedProducts,
    documentCollection,
  } = productPageData;

  const accessoriesModel: ItemListingBlockModel = {
    items: accessories,
    heading: accessoriesLabel,
    link: undefined,
    inEditMode: false,
  };

  const similarProductsModel: ItemListingBlockModel = {
    items: similarProducts,
    heading: relatedProductsLabel,
    link: undefined,
    inEditMode: false,
  };

  const recentlyVisitedProductsModel: ItemListingBlockModel = {
    items: recentlyVisitedProducts,
    heading: recentlyVisitedLabel,
    link: undefined,
    inEditMode: false,
  };

  useEffect(() => {
    var cookieValue = readCookie('recentlyVisitedProducts');
    if (!cookieValue) {
      createCookie('recentlyVisitedProducts', variantCode, 30);
      return;
    }
    let cookieList = cookieValue?.split('&');
    let sliced: string[] = [];

    if (cookieList.includes(variantCode)) {
      cookieList.splice(cookieList.indexOf(variantCode), 1);
      cookieList.unshift(variantCode);
      if (cookieList.length > 8) {
        sliced = cookieList.slice(0, 8);
      }
      createCookie(
        'recentlyVisitedProducts',
        !!sliced.length ? sliced.join('&') : cookieList.join('&'),
        30
      );
      return;
    } else if (!cookieList.includes(variantCode)) {
      cookieList.unshift(variantCode);
      if (cookieList.length > 2) {
        sliced = cookieList.slice(0, 8);
      }
      createCookie(
        'recentlyVisitedProducts',
        !!sliced.length ? sliced.join('&') : cookieList.join('&'),
        30
      );
    }
  });

  const onReadMore = () => {
    if (descriptionRef && descriptionRef.current) {
      setOpenDescriptionAccordion(true);
      scrollWithOffset(descriptionRef.current);
    }
  };
  const evenDetails =
    Object.keys(productPageData.productDetails).length % 2 === 0;

  useEffect(() => {
    if (
      !productPageData?.tillForsaljningPaFoljandeKanaler?.includes(
        productPageData.channelId
      )
    ) {
      setHideRule2(true);
    }

    if (!productPageData.visaPaMT && !productPageData.visaPaZE) {
      setHideRule1(true);
    }

    if (
      !productPageData.price.hasPrice ||
      !productPageData?.tillForsaljningPaFoljandeKanaler?.includes(
        productPageData.channelId
      )
    ) {
      setHideFromMarket(true);
      productPageData.hideStockStatus = true;
      productPageData.isNetPrice = true;
    } else {
      setHideFromMarket(false);
      if (!productPageData.visaPaMT && !productPageData.visaPaZE) {
        setHideFromMarket(true);
        productPageData.hideStockStatus = true;
        productPageData.isNetPrice = true;
      }
    }
  }, [productPageData]);

  return (
    <main>
      <StructuredProductData
        product={productPageData}
        hideRule1={hideRule1}
        hideRule2={hideRule2}
        hideFromMarket={hideFromMarket}
      />

      <Section top={true}>
        <ContentContainer>
          <Top>
            <ProductImages>
              {sliderImages && sliderImages.length > 0 && (
                <ProductImagesContainer
                  key={code}
                  sliderImages={sliderImages}
                  productName={productPageData.displayName}
                />
              )}
            </ProductImages>
            <AttributeAreaWrapper>
              <AttributeArea
                key={code}
                productPage={productPageData}
                hideRule1={hideRule1}
                hideRule2={hideRule2}
                hideFromMarket={hideFromMarket}
                onReadMore={() => onReadMore()}
              />
            </AttributeAreaWrapper>
          </Top>
        </ContentContainer>
      </Section>
      <Section>
        <ContentContainer>
          <DocumentContainer>
            {!!documentCollection?.length && (
              <>
                {documentCollection.map((link: DocumentModel) => (
                  <DocumentLinkWrapper key={link.url}>
                    <StyledIconLink
                      key={link.url}
                      href={link.url}
                      type={LinkType.Document}
                      useHoverEffect={false}
                    >
                      {link.description ? link.description : link.name}
                    </StyledIconLink>
                  </DocumentLinkWrapper>
                ))}
              </>
            )}
            <DocumentLinkWrapper key={'imageszip'}>
              <StyledIconLink
                key={'imageszip'}
                href={`/api/product/GetZip?productId=${productPageData?.baseProductId}`}
                type={LinkType.Document}
                target={'_blank'}
                useHoverEffect={false}
              >
                {downloadImagesLabel}
              </StyledIconLink>
            </DocumentLinkWrapper>
          </DocumentContainer>
          <Accordion title={detailsLabel} topBorder>
            {!(Object.keys(productPageData.productDetails).length === 0) && (
              <DetailsContainer>
                {Object.keys(productPageData.productDetails).map((x, index) => (
                  <ProductDetail
                    key={index}
                    left={index % 2 === 0}
                    right={index % 2 !== 0}
                    noBorder={
                      (!evenDetails &&
                        index ===
                          Object.keys(productPageData.productDetails).length -
                            1) ||
                      (evenDetails &&
                        index >=
                          Object.keys(productPageData.productDetails).length -
                            2)
                    }
                  >
                    <DetailsKey>
                      {x.charAt(0).toUpperCase() + x.slice(1)}
                    </DetailsKey>
                    <DetailsValue>
                      {productPageData.productDetails[x]}
                    </DetailsValue>
                  </ProductDetail>
                ))}
              </DetailsContainer>
            )}
          </Accordion>
          {(productPageData.extendedDescription ||
            productPageData.shortDescription) && (
            <div ref={descriptionRef}>
              <Accordion
                title={descriptionLabel}
                open={openDescriptionAccordion}
              >
                <Content>
                  {productPageData.extendedDescription
                    ? productPageData.extendedDescription
                    : productPageData.shortDescription
                    ? productPageData.shortDescription
                    : ''}
                </Content>
              </Accordion>
            </div>
          )}
        </ContentContainer>
      </Section>
      {similarProducts && similarProducts.length > 0 && (
        <RelatedProcuctsSection>
          <StyledGrid>
            <ItemListingBlock content={similarProductsModel} />
          </StyledGrid>
        </RelatedProcuctsSection>
      )}
      {accessories && accessories.length > 0 && (
        <RelatedProcuctsSection>
          <StyledGrid>
            <ItemListingBlock content={accessoriesModel} />
          </StyledGrid>
        </RelatedProcuctsSection>
      )}
      {recentlyVisitedProducts && recentlyVisitedProducts.length > 0 && (
        <RelatedProcuctsSection>
          <StyledGrid>
            <ItemListingBlock content={recentlyVisitedProductsModel} />
          </StyledGrid>
        </RelatedProcuctsSection>
      )}
      <Section>
        <Bottom>
          <Grid type={12} data-content-area>
            {Array.isArray(contentArea) && (
              <ContentArea childItems={contentArea} />
            )}
          </Grid>
        </Bottom>
      </Section>
    </main>
  );
}

const Section = styled('section', {
  backgroundColor: '$white',
  position: 'relative',
  maxW: '100%',
  pt: 6,
  '@mediaMinMedium': {
    pt: 8,
  },
  '@mediaMinLarge': {
    pt: 16,
    pb: 8,
  },
  variants: {
    top: {
      true: {
        '@mediaMinLarge': {
          pt: 8,
        },
      },
    },
  },
});

const DetailsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  pb: 6,
});

const StyledIconLink = styled(IconLink, {
  wh: 'auto',
  flexDirection: 'row',
});

const DetailsKey = styled('span', {
  fs: 5,
  fontWeight: 500,
  textTransform: 'uppercase',
  mr: 4,
  '@mediaMinLarge': {
    fs: 6,
    minW: '48%',
    maxW: '48%',
    mr: 8,
  },
});

const DetailsValue = styled('span', {
  fs: 6,
  '@mediaMinLarge': {
    fs: 7,
  },
});

const ProductDetail = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gridColumnEnd: 'span 2',
  py: 4,
  borderBottom: '1px solid #D9D9D9',
  '@mediaMaxMedium': {
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  '@mediaMaxLarge': {
    fs: 7,
    justifyContent: 'space-between',
  },
  '@mediaMinMedium': {
    gridColumnEnd: 'span 1',
  },
  '@mediaMinLarge': {
    py: 6,
  },
  variants: {
    left: {
      true: {
        '@mediaMinMedium': {
          pr: 4,
        },
      },
    },
    right: {
      true: {
        '@mediaMinMedium': {
          pl: 4,
        },
      },
    },
    noBorder: {
      true: {
        '@mediaMinMedium': { borderBottom: 'none' },
      },
    },
  },
});

const StyledGrid = styled('div', {
  maxWidth: '$contentMaxWidth',
  mx: 'auto',
  px: 4,
  '@mediaMinLarge': {
    px: 5,
  },
});

const DocumentContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  my: 8,
  gap: '32px',
  '@mediaMaxMedium': {
    mb: 4,
    rowGap: '16px',
  },
});

const DocumentLinkWrapper = styled('div', {});

const Top = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  w: '100%',
  '@mediaMinLarge': {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    g: 12,
  },
});

const ProductImages = styled('div', {
  px: 0,
  wh: '100%',
  gridColumnEnd: 'span 1',
  '@mediaMinLarge': {
    pr: 8,
    borderRight: '1px solid $grey100',
  },
});

const AttributeAreaWrapper = styled('div', {});

const RelatedProcuctsSection = styled('section', {
  backgroundColor: '$white',
  pb: 8,
  pt: 4,
});

const Content = styled(Section, {
  '@mediaMaxLarge': {
    fs: 7,
  },
  backgroundColor: '$white',
  pb: 8,
  pt: 4,
  px: 4,
});

const Bottom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pl: 0,
  h: 'auto',
  mx: 8,
  mt: 8,
  w: '100%',
  backgroundColor: '$white',
  '@mediaMaxSmall': {
    mx: 0,
  },
});

export default ProductPage;
