import { canUseDOM } from './Helpers';

// usage
// Cookie.createCookie("myCookieUniqueName", value, 30);
// Cookie.createCookie("myJsonCookieUniqueName", json, 30);
export function createCookie(name, value, days) {
  if (!canUseDOM()) return;

  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

// usage
// let value = Cookie.readCookie("myCookieUniqueName");
// let json = JSON.parse(Cookie.readCookie("myJsonCookieUniqueName");
export function readCookie(name) {
  if (!canUseDOM()) return;

  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  createCookie(name, '', -1);
}
