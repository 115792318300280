import Button from '../../Atoms/Buttons/Button';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import KexLink from '../../Kex/KexLink';
import { styled } from '../../stitches.config';
import GridItem from '../../Atoms/Grids/GridItem';
import ContentAreaItem from '../../Shared/Models/ContentAreaItem.interface';
import IItemListingBlock from '../../Blocks/ItemListingBlock/Models/ItemListingBlockModel.interface';
import ItemListingBlockSlider from '../../Shared/Common/ItemListingBlockSlider';

interface ItemListingBlockModel extends ContentAreaItem {
  content: IItemListingBlock;
  blockId: any;
}

function ItemListingBlock({
  content: { items, link, heading },
  blockId,
}: ItemListingBlockModel) {
  const MainContent = ItemListingBlockSlider(items);

  return (
    <GridItem>
      <Container data-litium-block-id={blockId}>
        <HeadingWrapper>
          <StyledH2>{heading}</StyledH2>
        </HeadingWrapper>
        <ContentWrapper>
          <MainContent />
        </ContentWrapper>
        {link && (
          <LinkContainer>
            <StyledLink href={link.href}>
              <Button size="s" type="primary">
                {link.text}
              </Button>
            </StyledLink>
          </LinkContainer>
        )}
      </Container>
    </GridItem>
  );
}

export default ItemListingBlock;

const Container = styled('div', {});

const ContentWrapper = styled('div', {
  mx: -2,
});

const HeadingWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const StyledH2 = styled(H2, {
  fs: 10,
});

const LinkContainer = styled('div', {
  w: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
});

const StyledLink = styled(KexLink, {
  py: 6,
  '&:hover': {
    textDecoration: 'underline',
  },
  '@mediaMaxSmall': {
    mx: 'auto',
  },
});
