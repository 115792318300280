import { styled } from '../../stitches.config';

type PropType = {
  layout?: string;
  children: JSX.Element | string;
};

function GridItem({ layout = '1/1', children }: PropType) {
  return (
    <GridItemContainer data-gridsize={layout}>{children}</GridItemContainer>
  );
}

const GridItemContainer = styled('div', {
  wh: '100%',
  mx: 'auto',
  gridColumnStart: 'auto',
  gridColumnEnd: 'span 12',
  '@mediaMinMedium': {
    maxW: 'unset',
    '&:nth-child(n)[data-gridsize="1/2"], &:nth-child(n)[data-gridsize="1/3"], &:nth-child(n)[data-gridsize="1/4"]':
      {
        gridColumnEnd: 'span 6',
      },
  },
  '@mediaMinLarge': {
    '&:nth-child(n)[data-gridsize="1/3"]': {
      gridColumnEnd: 'span 4',
    },
    '&:nth-child(n)[data-gridsize="1/4"]': {
      gridColumnEnd: 'span 3',
    },
    mx: 0,
  },
  '&:nth-child(n)[data-gridsize="FullWidth"]': {
    maxW: '$maxWidthMobile',
    '@mediaMinLarge': {
      left: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      maxW: '100vw',
      position: 'relative',
      right: '50%',
      w: '100vw',
    },
    px: 0,
  },
});

export default GridItem;
